import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const RefundService = {
  getListRefund() {
    return axios.get(`${cleanedBackendUrl}/refund`);
  },
  processRefund(refundId, status, rejectReason) {
    return axios.post(
      `${cleanedBackendUrl}/refund/${refundId}/process`,
      {
        status: status,
        rejectReason: rejectReason
      }
    );
  }
}

export default RefundService;