import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreIcon from "@mui/icons-material/MoreVert";
import { bankCodes } from "../helper/bankCodes";
import {
  Backdrop,
  Container,
  Fade,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Alert,
  Tooltip,
  Chip,
  Card,
  CardContent,
  TextareaAutosize,
  FormHelperText,
  Autocomplete,
  TextField,
} from "@mui/material";

import axios from "axios";
import { useAuth } from "../authContext";
import logoToko from "../assets/logo_copy.png";
import { NumericFormat } from "react-number-format";
import Navigation from "./navigation";
import Footer from "./components/footer/footer";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(3),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const btnPaymentStyle = {
  borderRadius: "10px",
  width: "100%",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "1px solid black",
  padding: 5,
  // marginLeft: 25,
  textTransform: "none",
};

const btnBackStyle = {
  borderRadius: "10px",
  color: "white",
  width: "10vw",
  backgroundColor: "black",
  border: "1px solid white",
  padding: 5,
  textTransform: "none",
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
  overflowX: "hidden",
};

const styleModalRefundList = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "100%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
};

const styleModalRefundAdd = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
};

const tabStyle = {
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  backgroundColor: "white",
  color: "black", 
};

const styleModalLogout = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
};

export default function HistoryPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [DataCust, setDataCust] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [errors, setErrors] = useState("");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const { auth, logout } = useAuth();
  const getUsername = auth.user ? auth.user.username : "";
  const getId = auth.user ? auth.user.id : "";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const storedOrderId = localStorage.getItem("orderid");
  const [value, setValue] = useState(0);
  const [orderData, setOrderData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [openRefundList, setOpenRefundList] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openAddRefund, setOpenAddRefund] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [loadingAddRefund, setLoadingAddRefund] = useState(false);
  const [reason, setReason] = useState("");
  const [bank, setBank] = useState(null);
  const [accountNo, setAccountNo] = useState("");

  const validate = () => {
    let tempErrors = {};
    if (!reason) {
      tempErrors.reason = "Alasan refund harus diisi";
    }

    if (!bank) {
      tempErrors.bank = "Bank/E-Wallet Tujuan harus diisi"
    }

    if (!accountNo) {
      tempErrors.accountNo = "No. Rekening / HP harus diisi"
    }

    setFormErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleOpenRefundList = (order) => {
    setSelectedOrder(order)
    setRefundList(order.refunds);
    setOpenRefundList(true);
  }

  const handleCloseRefundList = () => {
    setOpenRefundList(false);
    setRefundList([]);
  }

  const handleCloseAddRefund = () => {
    setOpenAddRefund(false);
    setReason("");
    setBank(null);
    setAccountNo("");
  }

  const deleteItem = async () => {
    setLoadingDelete(true)
    try {
      for (let order of waitingForPaymentListFiltered[0].orderList) {
        await axios.put(
          `${cleanedBackendUrl}/orders/${order.id}/status`,
          {
            id: order.id,
            status: "CANCELED"
          }
        )
      }
      fetchDataOrderbyPhone()
    } catch (error) {
      console.error(error);
      setMsgError("Batal pesanan gagal")
    } finally {
      setLoadingDelete(false)
      handleCloseDelete()
    }
  }

  const handleRowClick = (id, status) => {
    if (status?.key === "DRAFT" || status?.key === "WAITING_FOR_PAYMENT") {
      navigate(`/customer/checkoutPage/${id}`);
    } else {
      setMsgError("Order is finished.");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const handleNavigateHistory = () => {
    navigate('/customer/historyPage');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

// Handle Tab Change
const handleChange = (event, newValue) => {
  setValue(newValue);
};

const fetchDataOrderbyPhone = async () => {
  setIsLoadingOrder(true); // Set loading state to true
  try {
    const response = await axios.get(
      `${cleanedBackendUrl}/orders?phone-number=${DataCust.phonenumber}`
    );
    console.log(response);
    setOrderData(response.data || {});
    console.log(orderData)
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoadingOrder(false); // Set loading state to false
  }
};

const getStatusColor = (key) => {
  let bg = ''
  let text = ''

  if (key === 'DRAFT') {
    bg = '#A0A0A0'
    text = 'black'
  } else if (key === 'WAITING_FOR_PAYMENT') {
    bg = '#FE8A01'
    text = 'white'
  } else if (key === 'PAID') {
    bg = '#5DB7DE'
    text = 'black'
  } else if (key === 'PACKAGED') {
    bg = '#0074CC'
    text = 'white'
  } else if (key === 'ON_DELIVERY') {
    bg = '#9B30FF'
    text = 'white'
  } else if (key === 'DELIVERED') {
    bg = '#19B600'
    text = 'white'
  } else if (key === 'CANCELED') {
    bg = '#E50B0B'
    text = 'white'
  }

  return {
    background: bg,
    color: text
  }
};

const handleAddRefund = async (e) => {
  e.preventDefault();
  if(validate()) {
    try {
      setLoadingAddRefund(true);
      const response = await axios.post(
        `${cleanedBackendUrl}/refund`,
        {
          customerId: getId,
          paymentReferenceId: selectedOrder?.paymentReferenceId,
          reason: reason,
          accountNumber: accountNo,
          bankCode: bank?.bankCode
        }
      )

      if (response) {
        setShowSuccess(true);
        setMsgSuccess("Berhasil melakukan request refund");
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);

        setLoadingAddRefund(false);
        handleCloseAddRefund();
        handleCloseRefundList();
        fetchDataOrderbyPhone();
      }
    } catch (error) {
      setShowError(true);
      setMsgError("Gagal Membuat Refund");
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      setLoadingAddRefund(false);
    }
  } else {
    console.log(formErrors)
  }
}

// Data Destructuring from props
const {
  orderList,
  canceledList,
  waitingForPaymentList,
  paidList,
  packagedList,
  onDeliveryList,
  deliveredList,
} = orderData;

const {
  orderListFiltered,
  canceledListFiltered,
  waitingForPaymentListFiltered,
  paidListFiltered,
  packagedListFiltered,
  onDeliveryListFiltered,
  deliveredListFiltered,
} = filteredData;

useEffect(() => {
  const filtered = {}
  for (const key in orderData) {
    const baseData = orderData[key];
    if(baseData == null) continue;

    const notDoneOrder = []
    const completeOrder = []
    const canceledOrder = []

    for (const data of baseData) {
      if (data.status.key === 'WAITING_FOR_PAYMENT') {
        notDoneOrder.push(data)
      } else if (data.paymentReferenceId != null) {
        completeOrder.push(data)
      } else {
        canceledOrder.push(data)
      }
    }

    const notDoneOrderSorted = [];
    notDoneOrder.forEach((order) => {
      let existingGroup = notDoneOrderSorted.find(
        (group) => group.phoneNumber === order.phoneNumber
      );

      if (!existingGroup) {
        existingGroup = {
          username: order.username,
          phoneNumber: order.phoneNumber,
          paymentReferenceId: null,
          updatedDate: order.updatedDate,
          refunds: order.refunds,
          orderList: []
        };
        notDoneOrderSorted.push(existingGroup);
      }

      existingGroup.orderList.push(order);
    });

    const completeOrderSorted = [];
    completeOrder.forEach((order) => {
      let existingGroup = completeOrderSorted.find(
        (group) => group.phoneNumber === order.phoneNumber && group.paymentReferenceId === order.paymentReferenceId
      );

      if (!existingGroup) {
        existingGroup = {
          username: order.username,
          phoneNumber: order.phoneNumber,
          paymentReferenceId: order.paymentReferenceId,
          updatedDate: order.updatedDate,
          refunds: order.refunds,
          orderList: []
        };
        completeOrderSorted.push(existingGroup);
      }

      existingGroup.orderList.push(order);
    })

    const canceledOrderSorted = [];
    canceledOrder.forEach((order) => {
      canceledOrderSorted.push({
        username: order.username,
        phoneNumber: order.phoneNumber,
        paymentReferenceId: order.paymentReferenceId,
        updatedDate: order.updatedDate,
        refunds: order.refunds,
        orderList: [order]
      })
    })

    const sortedData = [...notDoneOrderSorted, ...completeOrderSorted, ...canceledOrderSorted]
    sortedData.sort((a, b) => {
      const [date1, hour1] = a.updatedDate?.split(" ");
      const [day1, month1, year1] = date1?.split("/").map(Number);
      const dateA = new Date(year1, month1 - 1, day1, ...hour1.split(":").map(Number));

      const [date2, hour2] = b.updatedDate?.split(" ");
      const [day2, month2, year2] = date2?.split("/").map(Number);
      const dateB = new Date(year2, month2 - 1, day2, ...hour2.split(":").map(Number));

      return dateB - dateA
    })

    filtered[key + 'Filtered'] = sortedData
  }

  setFilteredData(filtered)
}, [orderData])


// TabPanel Component: Handles displaying content of each tab
function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

// OrdersTable Component: Displays a table with order data
function OrdersTable({ data, message }) {
  if (data?.length === 0) {
    return <div>{message}</div>;
  }

  return (
    <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Order Item</th>
          <th>Order Date</th>
          <th>Phone Number</th>
          <th>Status</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((order) => (
          <tr
            key={order.id}
            onClick={() => handleRowClick(order.id, order.status)}
            style={{ cursor: "pointer" }}
          >
            <td>{order.id}</td>
            <td>
              {order.itemList?.map((item) => (
                <td key={item.id} sx={{ border: "none" }}>
                  {item?.name} - {item?.typeName} ({item?.typeVariant})
                </td>
              ))}
            </td>
            <td>{order.updatedDate}</td>
            <td>{order.phoneNumber}</td>
            <td>{order.status?.label}</td> {/* Handles status null check */}
            <td>{formatCurrency(order.price)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function OrdersTab({data, message}) {
  if (data == null) {
    return;
  }

  if (data?.length === 0) {
    return <div>{message}</div>;
  }

  return (
    <>
      {
        data.map(order => (
          
          <Grid container marginBottom={3} key={order.orderList[0].id}>
            <Grid item xs={12} sx={{ border: '1px solid white', borderTopRightRadius: "10px", borderTopLeftRadius: "10px", background: "white" }}>
              <Grid container color="black" padding="20px">
                <Grid item xs={6} display="flex" alignItems="center">
                  {order.updatedDate.split(" ")[0]} - {order.username}
                </Grid>
                <Grid item xs={6} textAlign="end">
                  {
                    (
                      order.orderList[0].status.key === "DRAFT" ||
                      order.orderList[0].status.key === "WAITING_FOR_PAYMENT"
                    ) 
                    && <Button onClick={() => navigate(`/customer/checkoutPage/${order.orderList[0].id}`)} 
                        style={{ color: 'white', background: 'black' }}>Checkout</Button> 
                  }
                  {
                    ( order.orderList[0].status.key === 'PAID' )
                    && <Button onClick={() => handleOpenRefundList(order)}
                      style={{ color: 'white', background: 'black' }}>Request Refund</Button> 
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ border: '1px solid white' }}>
              <Grid container padding="20px 40px 10px">
                {
                  order.orderList.map((orderData, index) => {

                    return orderData.itemList.map((item, itemIndex) => (
                      <Grid item xs={12} borderBottom="1px solid white" padding="10px" key={item.id}>
                        <Grid container>
                          <Grid item xs={12} sm={4} sx={{ marginBottom: {xs: "10px", sm: "0"} }}>
                            {item?.typeCode || "-"}{" "}
                            {orderData.id.slice(0, 2) === 'CW' ? '' : `(${orderData.id.slice(1, 4)})`} -{" "}
                            {item?.typeVariant || "-"}{" "}
                            - {orderData.id}
                          </Grid>
                          <Grid item xs={6} sm={4} sx={{ textAlign: {xs: "start", sm: "center"} }}>
                            {item?.name}
                          </Grid>
                          <Grid item xs={6} sm={4} textAlign="end" fontWeight="bold" >
                            <NumericFormat
                              value={orderData.price}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix={"Rp. "}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  })
                }
                <Grid item xs={12} padding="10px">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: {xs: "10px", sm: "10px", md: "0"} }}>
                      <Typography variant="h6" fontWeight="bold">Alamat Pengiriman</Typography>
                      <Typography variant="p" fontSize=".8em">
                        {
                          order.orderList[0]?.addressDetail
                          ? `${order.orderList[0]?.addressDetail}, ${order.orderList[0]?.addressNeighborhood}, ${order.orderList[0]?.addressSubdistrict}, ${order.orderList[0]?.addressDistrict}, ${order.orderList[0]?.addressProvince}`
                          : '-' 
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: {xs: "10px", sm: "10px", md: "0"} }} display="flex" alignItems="center" justifyContent="end">
                      <Typography variant="h6" color="#FE8A01">
                        TOTAL{" "}
                        <NumericFormat
                          value={order.orderList.reduce((sum, item) => sum + item.price, 0)}
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={"RP "}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="end">
                      <Chip label={order.orderList?.[0]?.status?.label} style={getStatusColor(order.orderList?.[0]?.status?.key)}></Chip>
                      {
                        (
                          order.orderList[0].status.key === "DRAFT" ||
                          order.orderList[0].status.key === "WAITING_FOR_PAYMENT"
                        ) 
                        && <Button onClick={handleOpenDelete}
                            style={{ color: 'white', background: '#E50B0B', marginLeft: "20px" }}>
                              Cancel
                            </Button> 
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      }
    </>
  );
}

  const fetchDataCust = async () => {
    setDataCust(auth.user);
  };

  useEffect(() => {
    fetchDataCust();
  }, []);

  useEffect(() => {
    console.log("Data Customer:", DataCust);
    fetchDataOrderbyPhone();
  }, [DataCust]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("orderid");
    setOpenLogout(false);
    logout();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ backgroundColor: "#FE8A01" }}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ minHeight: "80vh", marginBottom: "50px" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            borderRadius: 3,
            boxShadow: "10px 10px 5px gray",
            backgroundColor: "black",
            height: "auto",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ position: "relative" }}>
              <Box
                sx={{ backgroundColor: "#FE8A01", height: "auto", padding: 5 }}
              >
                <Typography variant="h3" fontWeight={"bolder"}>
                  History
                </Typography>
              </Box>
              <img
                src={logoToko}
                alt="Lique Store"
                style={{ width: 500, maxWidth: "100%", position: "absolute", top: 60, zIndex: 1 }}
              />
            </Grid>
            <Grid item xs={12} marginTop={10}>
              <Box sx={{ padding: {md: 2, sm: 0, xs: 0} }}>
                <Box sx={{ padding: {md: 2, sm: 0, xs: 0}, margin: {md: 4, sm: 2, xs: 0}, color: "white" }}>
                  <Typography variant="h4">Transaction History</Typography>
                  <Grid container spacing={3} marginTop={3}>
                    {}
                  </Grid>

                  <Box>
                    {/* Tab Headers */}
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable" // Enable scrollable behavior
                      scrollButtons="auto" // Show scroll buttons if tabs overflow
                      allowScrollButtonsMobile // Allow scroll buttons to appear on mobile
                      sx={{
                        "& .MuiTabs-scroller": {
                          overflowY: "hidden", // Disable vertical scrolling
                          overflowX: "auto", // Enable horizontal scrolling only
                        },
                        "& .MuiTabs-flexContainer": {
                          flexWrap: "nowrap", // Prevent tabs from wrapping into multiple lines
                        },
                        "& .MuiTab-root": {
                          minWidth: "120px", // Adjust tab width if necessary
                          whiteSpace: "nowrap", // Prevent text wrapping in tab headers
                        },
                      }}
                    >
                      <Tab
                        label={`Orders (${orderList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Canceled Orders (${canceledList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Waiting for Payment (${
                          waitingForPaymentList?.length || 0
                        })`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Paid Orders (${paidList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Packaged Orders (${packagedList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`On Delivery (${onDeliveryList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Delivered (${deliveredList?.length || 0})`}
                        sx={tabStyle}
                      />
                    </Tabs>

                    {/* Tab Panels */}
                    <TabPanel value={value} index={0}>
                      {/* <OrdersTable data={orderList} message="No Orders" /> */}
                      <OrdersTab data={orderListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {/* <OrdersTable
                        data={canceledList}
                        message="No Canceled Orders"
                      /> */}
                      <OrdersTab data={canceledListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {/* <OrdersTable
                        data={waitingForPaymentList}
                        message="No Orders Waiting for Payment"
                      /> */}
                      <OrdersTab data={waitingForPaymentListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      {/* <OrdersTable data={paidList} message="No Paid Orders" /> */}
                      <OrdersTab data={paidListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      {/* <OrdersTable
                        data={packagedList}
                        message="No Packaged Orders"
                      /> */}
                      <OrdersTab data={packagedListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      {/* <OrdersTable
                        data={onDeliveryList}
                        message="No Orders on Delivery"
                      /> */}
                      <OrdersTab data={onDeliveryListFiltered} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      {/* <OrdersTable
                        data={deliveredList}
                        message="No Delivered Orders"
                      /> */}
                      <OrdersTab data={deliveredListFiltered} message="No Orders" />
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={styleModal}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin membatalkan pesanan ini?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#E50B0B", color: "white" }}
                onClick={deleteItem}
                disabled={loadingDelete}
              >
                Batalkan Pesanan
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseDelete}
                sx={{
                  ml: 2,
                  backgroundColor: "#FE8A01",
                  color: "white",
                }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openRefundList}
        onClose={handleCloseRefundList}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRefundList}>
          <Box sx={styleModalRefundList}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Daftar Refund
            </Typography>
            <Grid container justifyContent="center">
              {
                refundList.map(refund => (
                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <Card sx={{ width: "100%", background: "#000", color: "#fff" }}>
                      <CardContent>
                        <Typography variant="h6">
                          Informasi Refund
                        </Typography>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={4}>
                            No. Refund
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.referenceId }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Alasan Refund
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.reason }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Bank / E-Wallet
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ bankCodes.find(bank => bank.bankCode === refund.bankCode)?.name }</b>
                          </Grid>

                          <Grid item xs={4}>
                            No. Rekening / HP
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.accountNumber }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Status
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.status }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Status
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.requestDate }</b>
                          </Grid>

                          {
                            refund.status === 'REJECTED' && (
                              <>
                                <Grid item xs={4}>
                                  Alasan Penolakan
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.rejectReason }
                                </Grid>
          
                                <Grid item xs={4}>
                                  Tanggal Penolakan
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.acceptedDate }
                                </Grid>
                              </>
                            )
                          }
          
                          {
                            !['WAITING', 'REJECTED'].includes(refund.status) && (
                              <>
                                <Grid item xs={4}>
                                  Tanggal Disetujui
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.acceptedDate }
                                </Grid>
                                
                                <Grid item xs={4}>
                                  Tanggal Selesai
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.completeDate }
                                </Grid>
                              </>
                            )
                          }
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              }
              {
                refundList.length === 0 && (
                  <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "20px" }}>
                    <Typography variant="p">
                      Belum ada data refund
                    </Typography>
                  </Grid>
                )
              }
              {
                ( refundList.length === 0 || ["REJECTED", "FAILED"].includes(refundList[0]?.status) )
                  && (
                    <Grid item xs={6}>
                      <Button onClick={() => setOpenAddRefund(true)} style={btnPaymentStyle}>Request Refund</Button>
                    </Grid>
                  )
              }
            </Grid>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openAddRefund}
        onClose={handleCloseAddRefund}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddRefund}>
          <Box sx={styleModalRefundAdd}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Request Refund
            </Typography>
            <form>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={12}>
                  <Typography>Bank Tujuan *</Typography>
                  <Autocomplete 
                    options={bankCodes}
                    getOptionLabel={(option) => `${option.name} (${option.type})`}
                    value={bank ? {...bank} : null}
                    isOptionEqualToValue={(option, value) => {
                      return option.bankCode === value.bankCode
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    onChange={(event, newValue) => setBank(newValue)} />
                  {!!formErrors.bank ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {formErrors.bank}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Typography>No Rekening / HP *</Typography>
                  <TextField
                    fullWidth
                    value={accountNo}
                    error={!!formErrors.accountNo}
                    helperText={formErrors.accountNo}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    onChange={(e) => setAccountNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} spacing={1}>
                  <Typography>Alasan Refund *</Typography>
                  <TextareaAutosize
                    style={{ width: '95%' }}
                    minRows={3}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {!!formErrors.reason ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {formErrors.reason}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleAddRefund}
                    fullWidth
                    disabled={loadingAddRefund}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
