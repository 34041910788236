import { useSpring, animated } from "@react-spring/web";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAuth } from "../authContext";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CssBaseline,
  Drawer,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import AdminSidebar from "./sidebar";
import { AccountCircle } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefundService from "../services/manager/refundService";
import { DataGrid } from "@mui/x-data-grid";
import { NumericFormat } from "react-number-format";
import { bankCodes } from "../helper/bankCodes";


const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const btnAddStyle = {
  justifyContent: "center",
  width: "15vw",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
  marginTop: "20px",
};

const btnAcceptStyle = {
  justifyContent: "center",
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
};

const btnRejectStyle = {
  justifyContent: "center",
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "#E50B0B",
  color: "white",
};

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const styleModalLogout = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function ReviewRefund() {
  const { auth, logout } = useAuth();
  
  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setmsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [openView, setOpenView] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [errors, setErrors] = useState({});

  const [selectedRefund, setSelectedRefund] = useState(null);
  const [listRefund, setListRefund] = useState([]);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const getusername = auth.user ? auth.user.username : "";
  const drawerWidth = 300;

  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const columns = [
    {
      field: "referenceId",
      headerName: "No. Refund",
      flex: 1,
      editable: false,
    },
    {
      field: "reason",
      headerName: "Alasan Refund",
      flex: 1,
      editable: false,
    },
    {
      field: "requestDate",
      headerName: "Tanggal Permintaan",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpenView(params.row)}>
            <VisibilityIcon />
          </IconButton>
        </div>
      ),
    },
  ]

  const handleOpenView = (refund) => {
    setSelectedRefund(refund);
    setOpenView(true);
  }

  const handleCloseView = (refund) => {
    setOpenView(false);
    setSelectedRefund(null);
    setReason("");
  }

  const getAllRefund = async () => {
    try {
      const response = await RefundService.getListRefund();
      if (response) {
        const data = response.data.refundList;
        setListRefund(data);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list refunds");
      activateError();
    }
  }

  const processRefund = async (status) => {
    if (status === 'REJECTED') {
      if (!reason) {
        setErrors({reason: "Alasan penolakan harus diisi jika melakukan penolakan"});
        return;
      }
    }

    try {
      setLoading(true);
      const response = await RefundService.processRefund(
        selectedRefund?.id,
        status,
        reason
      )

      if (response) {
        setmsgSuccess("Success processing refund");
        activateSuccess();

        getAllRefund();
        setOpenAccept(false);
        setOpenReject(false);
        handleCloseView();
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while processing refund");
      activateError();
    } finally {
      setLoading(false);
    }
  }

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  useEffect(() => {
    getAllRefund();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <AdminSidebar />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getusername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModalLogout}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ mr: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        
        <Toolbar />
      
        <RootContainer>
          {showSuccess && (
            <Alert
              variant="filled"
              severity="success"
              style={{ marginBottom: 3 }}
            >
              {msgSuccess}
            </Alert>
          )}
          {showError && (
            <Alert
              variant="filled"
              severity="error"
              style={{ marginBottom: 3 }}
            >
              {msgError}
            </Alert>
          )}

          <Typography variant="h3" marginBottom={5}>
            Kelola Refund
          </Typography>

          <Box sx={{ width: "100%" }}>
            <DataGrid
              rows={listRefund}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
        </RootContainer>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openView}
          onClose={handleCloseView}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openView}>
            <Box sx={styleModal}>
              <Grid container columnSpacing={1} justifyContent="space-evenly">
                <Grid item xs={4}>
                  No. Refund
                </Grid>
                <Grid item xs={8}>
                  { selectedRefund?.referenceId }
                </Grid>

                <Grid item xs={4}>
                  Alasan Refund
                </Grid>
                <Grid item xs={8}>
                  { selectedRefund?.reason }
                </Grid>
                
                <Grid item xs={4}>
                  Bank / E-Wallet
                </Grid>
                <Grid item xs={8}>
                  { bankCodes.find(bank => bank.bankCode === selectedRefund?.bankCode)?.name }
                </Grid>

                <Grid item xs={4}>
                  No. Rekening / HP
                </Grid>
                <Grid item xs={8}>
                  { selectedRefund?.accountNumber }
                </Grid>

                <Grid item xs={4}>
                  Status
                </Grid>
                <Grid item xs={8}>
                  { selectedRefund?.status }
                </Grid>
                
                <Grid item xs={4}>
                  Tanggal Permintaan
                </Grid>
                <Grid item xs={8}>
                  { selectedRefund?.requestDate }
                </Grid>

                {
                  selectedRefund?.status === 'REJECTED' && (
                    <>
                      <Grid item xs={4}>
                        Alasan Penolakan
                      </Grid>
                      <Grid item xs={8}>
                        { selectedRefund?.rejectReason }
                      </Grid>

                      <Grid item xs={4}>
                        Tanggal Penolakan
                      </Grid>
                      <Grid item xs={8}>
                        { selectedRefund?.acceptedDate }
                      </Grid>
                    </>
                  )
                }

                {
                  !['WAITING', 'REJECTED'].includes(selectedRefund?.status) && (
                    <>
                      <Grid item xs={4}>
                        Tanggal Disetujui
                      </Grid>
                      <Grid item xs={8}>
                        { selectedRefund?.acceptedDate }
                      </Grid>
                      
                      <Grid item xs={4}>
                        Tanggal Selesai
                      </Grid>
                      <Grid item xs={8}>
                        { selectedRefund?.completeDate }
                      </Grid>
                    </>
                  )
                }
              </Grid>

              <Grid container height="50px" marginTop={3} border="1px solid black">
                <Grid item xs={4} justifyContent="center" display="flex" alignItems="center">Produk</Grid>
                <Grid item xs={4} justifyContent="center" display="flex" alignItems="center">Tanggal Pesanan</Grid>
                <Grid item xs={4} justifyContent="center" display="flex" alignItems="center">Total Pesanan</Grid>
              </Grid>

              {
                selectedRefund?.listOrders.map(orderData => {
                  return orderData.itemList.map(item => (
                    <Grid container marginTop={2}>
                      <Grid item xs={4} textAlign="center">
                        {item?.typeCode || "-"}{" "}
                        {orderData.id.slice(1, 4)} -{" "}
                        {item?.typeVariant || "-"}{" "}
                        - {orderData.id}
                      </Grid>
                      <Grid item xs={4} textAlign="center">
                        {orderData.updatedDate}
                      </Grid>
                      <Grid item xs={4} textAlign="center">
                        <NumericFormat
                          value={orderData.price}
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={"Rp. "}
                        />
                      </Grid>
                    </Grid>
                  ))
                })
              }

              {
                selectedRefund?.status === 'WAITING' && (
                  <Grid container marginTop={3} justifyContent="space-evenly">
                    <Grid item xs={12} marginBottom={2}>
                      <Typography>Alasan Penolakan</Typography>
                      <TextareaAutosize
                        style={{ width: "100%" }}
                        minRows={3}
                        autoComplete="off"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                      {!!errors.reason ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {errors.reason}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item xs={5}>
                      <Button style={btnRejectStyle} disabled={loading}
                        onClick={() => processRefund('REJECTED')}>Tolak</Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button style={btnAcceptStyle} disabled={loading}
                        onClick={() => processRefund('ACCEPTED')}>Terima</Button>
                    </Grid>
                  </Grid>
                )
              }
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Box>
  )
}