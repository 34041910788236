export const bankCodes = [
  { bankCode: "ACEH", type: "Bank", name: "Bank Pembangunan Daerah Aceh" },
  { bankCode: "ACEH_UUS", type: "Bank", name: "Bank Pembangunan Daerah Aceh Syariah (UUS)" },
  { bankCode: "AGRONIAGA", type: "Bank", name: "Bank Raya Indonesia (formerly called Bank BRI Agroniaga)" },
  { bankCode: "ALADIN", type: "Bank", name: "Bank Aladin Syariah (formerly Bank Maybank Syariah Indonesia)" },
  { bankCode: "ALLO", type: "Bank", name: "Allo Bank Indonesia (formerly Bank Harda Internasional)" },
  { bankCode: "AMAR", type: "Bank", name: "Bank Amar Indonesia (formerly called Anglomas International Bank)" },
  { bankCode: "ANZ", type: "Bank", name: "Bank ANZ Indonesia" },
  { bankCode: "ARTHA", type: "Bank", name: "Bank Artha Graha International" },
  { bankCode: "BALI", type: "Bank", name: "Bank Pembangunan Daerah Bali" },
  { bankCode: "BAML", type: "Bank", name: "Bank of America Merill-Lynch" },
  { bankCode: "BANTEN", type: "Bank", name: "Bank Pembangunan Daerah Banten (formerly called Bank Pundi Indonesia)" },
  { bankCode: "BCA", type: "Bank", name: "Bank Central Asia (BCA)" },
  { bankCode: "BCA_DIGITAL", type: "Bank", name: "Bank Central Asia Digital (BluBCA)" },
  { bankCode: "BCA_SYR", type: "Bank", name: "Bank Central Asia (BCA) Syariah" },
  { bankCode: "BENGKULU", type: "Bank", name: "Bank Pembangunan Daerah Bengkulu" },
  { bankCode: "BISNIS_INTERNASIONAL", type: "Bank", name: "Bank Bisnis Internasional" },
  { bankCode: "BJB", type: "Bank", name: "Bank BJB" },
  { bankCode: "BJB_SYR", type: "Bank", name: "Bank BJB Syariah" },
  { bankCode: "BNC", type: "Bank", name: "Bank Neo Commerce (formerly Bank Yudha Bhakti)" },
  { bankCode: "BNI", type: "Bank", name: "Bank Negara Indonesia (BNI)" },
  { bankCode: "BNP_PARIBAS", type: "Bank", name: "Bank BNP Paribas" },
  { bankCode: "BOC", type: "Bank", name: "Bank of China (BOC)" },
  { bankCode: "BRI", type: "Bank", name: "Bank Rakyat Indonesia (BRI)" },
  { bankCode: "BSI", type: "Bank", name: "Bank Syariah Indonesia (BSI)" },
  { bankCode: "BTN", type: "Bank", name: "Bank Tabungan Negara (BTN)" },
  { bankCode: "BTN_UUS", type: "Bank", name: "Bank Tabungan Negara Syariah (BTN UUS)" },
  { bankCode: "BTPN_SYARIAH", type: "Bank", name: "BTPN Syariah (formerly called BTPN UUS and Bank Sahabat Purba Danarta)" },
  { bankCode: "BUKOPIN", type: "Bank", name: "Bank Bukopin" },
  { bankCode: "BUKOPIN_SYR", type: "Bank", name: "Bank Syariah Bukopin" },
  { bankCode: "BUMI_ARTA", type: "Bank", name: "Bank Bumi Arta" },
  { bankCode: "CAPITAL", type: "Bank", name: "Bank Capital Indonesia" },
  { bankCode: "CCB", type: "Bank", name: "China Construction Bank Indonesia (formerly called Bank Antar Daerah and Bank Windu Kentjana International)" },
  { bankCode: "CHINATRUST", type: "Bank", name: "Bank Chinatrust Indonesia" },
  { bankCode: "CIMB", type: "Bank", name: "Bank CIMB Niaga" },
  { bankCode: "CIMB_UUS", type: "Bank", name: "Bank CIMB Niaga Syariah (UUS)" },
  { bankCode: "CITIBANK", type: "Bank", name: "Citibank" },
  { bankCode: "COMMONWEALTH", type: "Bank", name: "Bank Commonwealth" },
  { bankCode: "DAERAH_ISTIMEWA", type: "Bank", name: "Bank Pembangunan Daerah Daerah Istimewa Yogyakarta (DIY)" },
  { bankCode: "DAERAH_ISTIMEWA_UUS", type: "Bank", name: "Bank Pembangunan Daerah Daerah Istimewa Yogyakarta Syariah (DIY UUS)" },
  { bankCode: "DANAMON", type: "Bank", name: "Bank Danamon" },
  { bankCode: "DANAMON_UUS", type: "Bank", name: "Bank Danamon Syariah (UUS)" },
  { bankCode: "DBS", type: "Bank", name: "Bank DBS Indonesia" },
  { bankCode: "DEUTSCHE", type: "Bank", name: "Deutsche Bank" },
  { bankCode: "DINAR_INDONESIA", type: "Bank", name: "Bank Dinar Indonesia" },
  { bankCode: "DKI", type: "Bank", name: "Bank DKI" },
  { bankCode: "DKI_UUS", type: "Bank", name: "Bank DKI Syariah (UUS)" },
  { bankCode: "FAMA", type: "Bank", name: "Bank Fama International" },
  { bankCode: "GANESHA", type: "Bank", name: "Bank Ganesha" },
  { bankCode: "HANA", type: "Bank", name: "Bank Hana" },
  { bankCode: "HSBC", type: "Bank", name: "HSBC Indonesia (formerly called Bank Ekonomi Raharja)" },
  { bankCode: "HSBC_UUS", type: "Bank", name: "Hongkong and Shanghai Bank Corporation Syariah (HSBC UUS)" },
  { bankCode: "IBK", type: "Bank", name: "Bank IBK Indonesia (formerly Bank Agris)" },
  { bankCode: "ICBC", type: "Bank", name: "Bank ICBC Indonesia" },
  { bankCode: "INA_PERDANA", type: "Bank", name: "Bank Ina Perdania" },
  { bankCode: "INDEX_SELINDO", type: "Bank", name: "Bank Index Selindo" },
  { bankCode: "INDIA", type: "Bank", name: "Bank of India Indonesia" },
  { bankCode: "JAGO", type: "Bank", name: "Bank Jago (formerly Bank Artos Indonesia)" },
  { bankCode: "JAMBI", type: "Bank", name: "Bank Pembangunan Daerah Jambi" },
  { bankCode: "JAMBI_UUS", type: "Bank", name: "Bank Pembangunan Daerah Jambi Syariah (UUS)" },
  { bankCode: "JASA_JAKARTA", type: "Bank", name: "Bank Jasa Jakarta" },
  { bankCode: "JAWA_TENGAH", type: "Bank", name: "Bank Pembangunan Daerah Jawa Tengah" },
  { bankCode: "JAWA_TENGAH_UUS", type: "Bank", name: "Bank Pembangunan Daerah Jawa Tengah Syariah (UUS)" },
  { bankCode: "JAWA_TIMUR", type: "Bank", name: "Bank Pembangunan Daerah Jawa Timur" },
  { bankCode: "JAWA_TIMUR_UUS", type: "Bank", name: "Bank Pembangunan Daerah Jawa Timur Syariah (UUS)" },
  { bankCode: "JPMORGAN", type: "Bank", name: "JP Morgan Chase Bank" },
  { bankCode: "JTRUST", type: "Bank", name: "Bank JTrust Indonesia (formerly called Bank Mutiara)" },
  { bankCode: "KALIMANTAN_BARAT", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Barat" },
  { bankCode: "KALIMANTAN_BARAT_UUS", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Barat Syariah (UUS)" },
  { bankCode: "KALIMANTAN_SELATAN", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Selatan" },
  { bankCode: "KALIMANTAN_SELATAN_UUS", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Selatan Syariah (UUS)" },
  { bankCode: "KALIMANTAN_TENGAH", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Tengah" },
  { bankCode: "KALIMANTAN_TENGAH_UUS", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Tengah Syariah (UUS)" },
  { bankCode: "KALIMANTAN_TIMUR", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Timur" },
  { bankCode: "KALIMANTAN_TIMUR_UUS", type: "Bank", name: "Bank Pembangunan Daerah Kalimantan Timur Syariah (UUS)" },
  { bankCode: "LAMPUNG", type: "Bank", name: "Bank Pembangunan Daerah Lampung" },
  { bankCode: "MALUKU", type: "Bank", name: "Bank Pembangunan Daerah Maluku" },
  { bankCode: "MANDIRI", type: "Bank", name: "Bank Mandiri" },
  { bankCode: "MANDIRI_TASPEN", type: "Bank", name: "Mandiri Taspen Pos (formerly called Bank Sinar Harapan Bali)" },
  { bankCode: "MASPION", type: "Bank", name: "Bank Maspion Indonesia" },
  { bankCode: "MAYAPADA", type: "Bank", name: "Bank Mayapada International" },
  { bankCode: "MAYBANK", type: "Bank", name: "Bank Maybank" },
  { bankCode: "MAYORA", type: "Bank", name: "Bank Mayora" },
  { bankCode: "MEGA", type: "Bank", name: "Bank Mega" },
  { bankCode: "MEGA_SYR", type: "Bank", name: "Bank Syariah Mega" },
  { bankCode: "MESTIKA_DHARMA", type: "Bank", name: "Bank Mestika Dharma" },
  { bankCode: "MIZUHO", type: "Bank", name: "Bank Mizuho Indonesia" },
  { bankCode: "MNC_INTERNASIONAL", type: "Bank", name: "Bank MNC Internasional" },
  { bankCode: "MUAMALAT", type: "Bank", name: "Bank Muamalat Indonesia" },
  { bankCode: "MULTI_ARTA_SENTOSA", type: "Bank", name: "Bank Multi Arta Sentosa" },
  { bankCode: "NATIONALNOBU", type: "Bank", name: "Bank Nationalnobu" },
  { bankCode: "NUSA_TENGGARA_BARAT", type: "Bank", name: "Bank Pembangunan Daerah Nusa Tenggara Barat" },
  { bankCode: "NUSA_TENGGARA_TIMUR", type: "Bank", name: "Bank Pembangunan Daerah Nusa Tenggara Timur" },
  { bankCode: "OCBC", type: "Bank", name: "Bank OCBC NISP" },
  { bankCode: "OCBC_UUS", type: "Bank", name: "Bank OCBC NISP Syariah (UUS)" },
  { bankCode: "OKE", type: "Bank", name: "Bank Oke Indonesia (formerly called Bank Andara)" },
  { bankCode: "PANIN", type: "Bank", name: "Bank Panin" },
  { bankCode: "PANIN_SYR", type: "Bank", name: "Bank Panin Syariah" },
  { bankCode: "PAPUA", type: "Bank", name: "Bank Pembangunan Daerah Papua" },
  { bankCode: "PERMATA", type: "Bank", name: "Bank Permata" },
  { bankCode: "PERMATA_UUS", type: "Bank", name: "Bank Permata Syariah (UUS)" },
  { bankCode: "PRIMA_MASTER", type: "Bank", name: "Prima Master Bank" },
  { bankCode: "QNB_INDONESIA", type: "Bank", name: "Bank QNB Indonesia (formerly called Bank QNB Kesawan)" },
  { bankCode: "RABOBANK", type: "Bank", name: "Bank Rabobank International Indonesia" },
  { bankCode: "RESONA", type: "Bank", name: "Bank Resona Perdania" },
  { bankCode: "RIAU_DAN_KEPRI", type: "Bank", name: "Bank Pembangunan Daerah Riau Dan Kepri" },
  { bankCode: "SAHABAT_SAMPOERNA", type: "Bank", name: "Bank Sahabat Sampoerna" },
  { bankCode: "SBI_INDONESIA", type: "Bank", name: "Bank SBI Indonesia" },
  { bankCode: "SEABANK", type: "Bank", name: "Bank Seabank Indonesia (formerly Bank Kesejahteraan Ekonomi)" },
  { bankCode: "SHINHAN", type: "Bank", name: "Bank Shinhan Indonesia (formerly called Bank Metro Express)" },
  { bankCode: "SINARMAS", type: "Bank", name: "Bank Sinarmas" },
  { bankCode: "SINARMAS_UUS", type: "Bank", name: "Bank Sinarmas Syariah (UUS)" },
  { bankCode: "STANDARD_CHARTERED", type: "Bank", name: "Standard Chartered Bank" },
  { bankCode: "SULAWESI", type: "Bank", name: "Bank Pembangunan Daerah Sulawesi Tengah" },
  { bankCode: "SULAWESI_TENGGARA", type: "Bank", name: "Bank Pembangunan Daerah Sulawesi Tenggara" },
  { bankCode: "SULSELBAR", type: "Bank", name: "Bank Pembangunan Daerah Sulselbar" },
  { bankCode: "SULSELBAR_UUS", type: "Bank", name: "Bank Pembangunan Daerah Sulselbar Syariah (UUS)" },
  { bankCode: "SULUT", type: "Bank", name: "Bank Pembangunan Daerah Sulut" },
  { bankCode: "SUMATERA_BARAT", type: "Bank", name: "Bank Pembangunan Daerah Sumatera Barat" },
  { bankCode: "SUMATERA_BARAT_UUS", type: "Bank", name: "Bank Pembangunan Daerah Sumatera Barat Syariah (UUS)" },
  { bankCode: "SUMSEL_DAN_BABEL", type: "Bank", name: "Bank Pembangunan Daerah Sumsel Dan Babel" },
  { bankCode: "SUMSEL_DAN_BABEL_UUS", type: "Bank", name: "Bank Pembangunan Daerah Sumsel Dan Babel Syariah (UUS)" },
  { bankCode: "SUMUT", type: "Bank", name: "Bank Pembangunan Daerah Sumut" },
  { bankCode: "SUMUT_UUS", type: "Bank", name: "Bank Pembangunan Daerah Sumut Syariah (UUS)" },
  { bankCode: "TABUNGAN_PENSIUNAN_NASIONAL", type: "Bank", name: "Bank Tabungan Pensiunan Nasional (BTPN)" },
  { bankCode: "TOKYO", type: "Bank", name: "Bank of Tokyo Mitsubishi UFJ (MUFJ)" },
  { bankCode: "UOB", type: "Bank", name: "Bank UOB Indonesia" },
  { bankCode: "VICTORIA_INTERNASIONAL", type: "Bank", name: "Bank Victoria Internasional" },
  { bankCode: "VICTORIA_SYR", type: "Bank", name: "Bank Victoria Syariah" },
  { bankCode: "WOORI", type: "Bank", name: "Bank Woori Indonesia" },
  { bankCode: "DANA", type: "E-Wallet", name: "DANA" },
  { bankCode: "GOPAY", type: "E-Wallet", name: "GoPay" },
  { bankCode: "LINKAJA", type: "E-Wallet", name: "LinkAja" },
  { bankCode: "OVO", type: "E-Wallet", name: "OVO" },
  { bankCode: "SHOPEEPAY", type: "E-Wallet", name: "ShopeePay" },
];