import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  CssBaseline,
  Drawer,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Modal,
  Select,
  styled,
  Tab,
  TableBody,
  TableContainer,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import SupervisorSidebar from "./sidebar";
import PropTypes from "prop-types";
import {
  AccountCircle,
  KeyboardArrowUp,
  KeyboardArrowDown
} from "@mui/icons-material";
import { useAuth } from "../authContext";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { set } from "date-fns";
import { bankCodes } from "../helper/bankCodes";
import './pemesanan2.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const styleModalRefundList = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "100%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
};

const styleModalRefundAdd = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
};

const btnPaymentStyle = {
  borderRadius: "10px",
  width: "100%",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "1px solid black",
  padding: 5,
  textTransform: "none",
};

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FE8A01 !important", // Indicator color with !important
  },
  "& .MuiTab-root": {
    color: "#000000 !important", // Default text color with !important
    textTransform: "none !important", // Remove uppercase text transformation with !important
  },
  "& .Mui-selected": {
    color: "#FE8A01 !important", // Color for selected tab text with !important
  },
});

const btnUpdatePesanan = {
  justifyContent: "center",
  width: "15vw",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
};

const btnPrintMassal = {
  justifyContent: "center",
  width: "150px",
  maxWidth: "100%",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "1px solid black",
};

const idrFormat = new Intl.NumberFormat("en-ID", {
  style: "currency",
  currency: "IDR",
});

const formatCurrency = (number) => {
  if (!number) number = 0;
  return idrFormat.format(number);
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const headCells = [
  { id: "phoneNumber", numeric: false, disablePadding: false, label: "Produk" },
  { id: "updatedDate", numeric: false, disablePadding: false, label: "Tanggal Pesanan" },

  { id: "price", numeric: true, disablePadding: false, label: "Total Pesanan" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "delivery",
    numeric: false,
    disablePadding: false,
    label: "Jasa Kirim",
  },
  { id: "id", numeric: false, disablePadding: false, label: "No Pesanan" },
  { id: "action", numeric: false, disablePadding: false, label: "Aksi" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: "#FE8A01" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Employee Data
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const parseDateString = (dateString) => {
  const [datePart, timePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/");
  return new Date(`${year}-${month}-${day}T${timePart}`);
};

const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "updatedDate") {
    // Convert date strings to date objects for comparison
    const dateA = parseDateString(a[orderBy]);
    const dateB = parseDateString(b[orderBy]);
    return dateB - dateA;
  } else if (orderBy === "status") {
    // Compare the status label
    const statusA = a[orderBy]?.label.toLowerCase();
    const statusB = b[orderBy]?.label.toLowerCase();
    if (statusB < statusA) {
      return -1;
    }
    if (statusB > statusA) {
      return 1;
    }
    return 0;
  } else {
    // Default comparison for other fields
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
};

function PemesananPage2() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");

  const drawerWidth = 300;
  const { auth, logout } = useAuth();
  const getusername = auth.user ? auth.user.username : "";
  const [openLogout, setOpenLogout] = useState(false);
  const [page, setPage] = useState("main");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgInsert, setMsgInsert] = useState("");
  const [msgError, setMsgError] = useState("");
  const [showError, setShowError] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedDate");
  var visibleRows = [];
  const [filteredOrderData, setFilteredOrderData] = useState(visibleRows);
  const [sortedOrderData, setSortedOrderData] = useState([]);
  const [ColourData, setColourData] = useState([]);
  const [hexcode, setHexcode] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [DataTipe, setDataTipe] = useState([]);
  const [TypeCode, setTypeCode] = useState([]);
  const [Orderid, setOrderid] = useState([]);
  const [OrderidDetail, setOrderidDetail] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openRows, setOpenRows] = useState({});
  const [updateOrderIsLoading, setUpdateOrderIsLoading] = useState(false);
  
  const [openRefundList, setOpenRefundList] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openAddRefund, setOpenAddRefund] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [loadingAddRefund, setLoadingAddRefund] = useState(false);
  const [reason, setReason] = useState("");
  const [bank, setBank] = useState(null);
  const [accountNo, setAccountNo] = useState("");
  const [currentTab, setCurrentTab] = useState("SEMUA");

  const handleRowToggle = (index) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific row's expand state
    }));
  };

  const [statusDetail, setStatusDetail] = useState(
    orderDetail.map((detail) => detail.status.key)
  );
  const [deleteRow, setDeleteRow] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    if (typeof property === 'object' && property !== null) {
      setOrderBy(property?.label);
    } else {
      setOrderBy(property);
    }
  };

  visibleRows = React.useMemo(() => {
    if (!filteredOrderData) {
      return [];
    }
    const data = filteredOrderData;

    // Apply search filter before sorting
    const filteredData = data.filter((row) =>
      Object.values(row).some((val) => {
        if (typeof val === 'object' && val !== null) {
          return String(val?.label).toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return String(val).toLowerCase().includes(searchTerm.toLowerCase());
        }
      })
    );

    // Sort the filtered data (assuming getComparator is a valid sorting function)
    return filteredData.sort(getComparator(order, orderBy));
  }, [filteredOrderData, searchTerm, order, orderBy]);
  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [open, setOpen] = React.useState(false);

  const handleOpenDelete = (row) => {
    setDeleteRow(row);
    console.log("Deleted row:", row);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${cleanedBackendUrl}/orders/${orderDetail[deleteRow].id}`
      );
      console.log(response.data);
      setShowSuccess(true);
      setMsgInsert("Berhasil Hapus Order");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      toMain();
    } catch (error) {
      console.error(error);
      setMsgError("Gagal Hapus Order");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
    setOpenDelete(false);
  };

  const handleUpdatePesanan = async (e) => {
    e.preventDefault();
    try {
      setUpdateOrderIsLoading(true);
      const response = await axios.put(
        `${cleanedBackendUrl}/orders/status`
      );
      console.log(response.data);
      setShowSuccess(true);
      setMsgInsert("Berhasil Update Status Order");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      // toMain();
    } catch (error) {
      console.error(error);
      setMsgError("Gagal Update Order");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      setUpdateOrderIsLoading(false);
    }
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);

    // Hide the notification after 3 seconds
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const handlePopperClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleNavigation = async (nav) => {
    console.log("Navigating to status:", nav);
    setCurrentTab(nav);
    setOpenRows({})
    // try {
    //   const response = await axios.put(`${cleanedBackendUrl}/orders/status`);
    //   console.log("Berhasil Update Status Order");
    //   console.log(response.data);
    // } catch (error) {
    //   console.error("Gagal Update Order");
    //   console.error(error);
    // }

    if (nav === "SEMUA") {
      setFilteredOrderData(orderData);
      console.log("Filtered Orders:", filteredOrderData);
    } else {
      const filteredOrders = orderData.filter(
        (order) => order.status.key === nav
      );
      console.log("Filtered Orders:", filteredOrders);
      setFilteredOrderData(filteredOrders);
    }
  };

  // const dataFilter = (order) => {
  // }
  useEffect(() => {
    const notDoneOrder = []
    const completeOrder = []
    const canceledOrder = []

    for (const data of filteredOrderData) {
      if (data.status.key === 'WAITING_FOR_PAYMENT') {
        notDoneOrder.push(data)
      } else if (data.paymentReferenceId != null) {
        completeOrder.push(data)
      } else {
        canceledOrder.push(data)
      }
    }

    const notDoneOrderSorted = [];
    notDoneOrder.forEach((order) => {
      let existingGroup = notDoneOrderSorted.find(
        (group) => group.phoneNumber === order.phoneNumber
      );

      if (!existingGroup) {
        existingGroup = {
          username: order.username,
          phoneNumber: order.phoneNumber,
          paymentReferenceId: null,
          updatedDate: order.updatedDate,
          refunds: order.refunds,
          orderList: []
        };
        notDoneOrderSorted.push(existingGroup);
      }

      existingGroup.orderList.push(order);
    });

    const completeOrderSorted = [];
    completeOrder.forEach((order) => {
      let existingGroup = completeOrderSorted.find(
        (group) => group.phoneNumber === order.phoneNumber && group.paymentReferenceId === order.paymentReferenceId
      );

      if (!existingGroup) {
        existingGroup = {
          username: order.username,
          phoneNumber: order.phoneNumber,
          paymentReferenceId: order.paymentReferenceId,
          updatedDate: order.updatedDate,
          refunds: order.refunds,
          orderList: []
        };
        completeOrderSorted.push(existingGroup);
      }

      existingGroup.orderList.push(order);
    })

    const canceledOrderSorted = [];
    canceledOrder.forEach((order) => {
      canceledOrderSorted.push({
        username: order.username,
        phoneNumber: order.phoneNumber,
        paymentReferenceId: order.paymentReferenceId,
        updatedDate: order.updatedDate,
        refunds: order.refunds,
        orderList: [order]
      })
    })

    const sortedData = [...notDoneOrderSorted, ...completeOrderSorted, ...canceledOrderSorted]
    sortedData.sort((a, b) => {
      const [date1, hour1] = a.updatedDate?.split(" ");
      const [day1, month1, year1] = date1?.split("/").map(Number);
      const dateA = new Date(year1, month1 - 1, day1, ...hour1.split(":").map(Number));

      const [date2, hour2] = b.updatedDate?.split(" ");
      const [day2, month2, year2] = date2?.split("/").map(Number);
      const dateB = new Date(year2, month2 - 1, day2, ...hour2.split(":").map(Number));

      return dateB - dateA
    })
    setSortedOrderData(sortedData);
  }, [filteredOrderData])

  const handleSubmit = async () => {
    console.log(orderDetail);

    try {
      for (let i = 0; i < orderDetail.length; i++) {
        // Prepare the data for the item update
        const putItemData = {
          id: orderDetail[i].id,
          phoneNumber: orderDetail[i].phoneNumber,
          itemList: [
            {
              typeCode: TypeCode[i], // Corresponds to the same index in TypeCode
            },
          ],
          price: orderDetail[i].price,
        };

        console.log("Item Data:", putItemData);

        // Send the PUT request to update the item details
        const itemResponse = await axios.put(
          `${cleanedBackendUrl}/orders/${orderDetail[i].id}/items`,
          putItemData
        );

        console.log("Item Response:", itemResponse);

        // Prepare the data for the status update
        const putStatusData = {
          id: orderDetail[i].id,
          status: statusDetail[i], // Corresponds to the same index in statusDetail
        };

        console.log("Status Data:", putStatusData);

        // Send the PUT request to update the status
        const statusResponse = await axios.put(
          `${cleanedBackendUrl}/orders/${orderDetail[i].id}/status`,
          putStatusData
        );

        console.log("Status Response:", statusResponse);
      }

      setShowSuccess(true);
      setMsgInsert("Berhasil Mengupdate Pesanan");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      setShowError(true);
      console.error("Error updating orders:", error);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      toMain();
    }
  };

  const printOrder = async (waybill, event) => {
    event.stopPropagation();
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/delivery/print/${waybill}`
      )

      if (response) {
        const data = response.data[0];
        if (data.success === "true") {
          const printLink = data.rotaPrintUrl;
          window.open(printLink, "_blank");
        } else {
          switch(data.reason) {
            case "S28":
              setMsgError("AWB has been cancelled");
              break;
            case "S32":
              setMsgError("AWB has been completed or returned");
              break;
            default:
              setMsgError("Oops, something went wrong");
              break;
          }
          
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 5000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const printOrderByStatus = async () => {
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/delivery/print/status/${currentTab}`
      )

      if (response) {
        const data = response.data[0];
        if (data.success === "true") {
          const printLink = data.rotaPrintUrl;
          window.open(printLink, "_blank");
        } else {
          switch(data.reason) {
            case "S28":
              setMsgError("AWB has been cancelled");
              break;
            case "S32":
              setMsgError("AWB has been completed or returned");
              break;
            default:
              setMsgError("Oops, something went wrong");
              break;
          }

          
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 5000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toMain = () => {
    fetchOrders();
    fetchItems();
    fetchStatus();
    setValue(0);
    handleNavigation("SEMUA");
    setPage("main");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toEdit = async (id, event) => {
    event.stopPropagation();
    fetchDataType();
    fetchDataColour();
    try {
      const selectedOrder = sortedOrderData[id];
      const selectedPhoneNumber = selectedOrder.phoneNumber;

      // Fetch orders with the same phone number
      const response = await axios.get(
        `${cleanedBackendUrl}/orders?phone-number=${selectedPhoneNumber}`
      );
      console.log(response.data);

      const filteredOrders = response.data.orderList.filter(
        (order) =>
          order.status.key === "DRAFT" ||
          order.status.key === "WAITING_FOR_PAYMENT"
      );

      setOrderDetail(filteredOrders);
      setOrderidDetail(Orderid[id]);
      console.log(id, page);
      setPage("edit");
    } catch (error) {
      console.error("Error fetching order data:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (orderDetail && orderDetail.length > 0) {
      const hexCodes = orderDetail.map((detail) => {
        const firstLetter = detail.id.charAt(0);
        const matchedColor = ColourData.find(
          (color) => color.code === firstLetter
        );
        return matchedColor ? matchedColor.hexCode : null;
      });

      setHexcode(hexCodes);
    }
  }, [orderDetail, ColourData]);

  const handleIdChange = (e, index) => {
    const newOrderDetail = [...orderDetail]; // Create a copy of the orderDetail array
    newOrderDetail[index] = {
      ...newOrderDetail[index],
      id: e.target.value, // Update the id of the specific item
    };
    setOrderDetail(newOrderDetail); // Update the state with the modified array
  };

  const handlePhoneNumberChange = (e) => {
    const newOrderDetail = [...orderDetail]; // Create a copy of the orderDetail array
    newOrderDetail[0] = {
      ...newOrderDetail[0],
      phoneNumber: e.target.value, // Update the phoneNumber of the first item
    };
    setOrderDetail(newOrderDetail); // Update the state with the modified array
  };

  const handlePriceChange = (values, index) => {
    setOrderDetail((prevOrderDetail) => {
      const updatedOrderDetail = [...prevOrderDetail]; // Copy the existing array
      updatedOrderDetail[index] = {
        ...updatedOrderDetail[index],
        price: values.floatValue, // Update the price of the specific item
      };
      return updatedOrderDetail; // Return the updated array
    });
  };

  const handleTypeChange = (event, newValue, index) => {
    setTypeCode((prevTypeCode) => {
      const updatedTypeCode = [...prevTypeCode];

      // If the index is valid and within bounds, update the specific index
      if (index >= 0) {
        // Ensure the array has enough length to accommodate the index
        while (updatedTypeCode.length <= index) {
          updatedTypeCode.push(null); // Add null values until the array is long enough
        }

        // Update the specific index with the new value (or null if newValue is empty)
        updatedTypeCode[index] = newValue ? newValue.value : null;
      }

      return updatedTypeCode; // Return the updated array
    });
  };

  const fetchDataType = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/items/types`);
      console.log(response.data.itemTypeList);
      setDataTipe(response.data.itemTypeList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataColour = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders/colors`);
      console.log(response.data.orderColorList);
      setColourData(response.data.orderColorList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDataCode = () => {};

  const optTipe = DataTipe.map((item) => ({
    id: item.id,
    name: item.name,
    varian: item.variant,
    value: item.code,
  }));

  const statusOptions = [
    { key: "DRAFT", label: "DRAFT" },
    { key: "WAITING_FOR_PAYMENT", label: "MENUNGGU PEMBAYARAN" },
    // { key: 'PAID', label: 'Paid' },
    // { key: 'SHIPPED', label: 'Shipped' },
    // { key: 'DELIVERED', label: 'Delivered' },
    // { key: 'CANCELED', label: 'Canceled' },
    // Add more statuses as needed
  ];

  const handleChangeStatus = (event, index) => {
    const newStatusKey = event.target.value;

    // Update the statusDetail array with the new value
    setStatusDetail((prevStatusDetail) => {
      const updatedStatusDetail = [...prevStatusDetail];
      updatedStatusDetail[index] = newStatusKey;
      return updatedStatusDetail;
    });
  };

  useEffect(() => {
    console.log("Order Detail", orderDetail);

    if (orderDetail && orderDetail.length > 0) {
      // Initialize TypeCode if not already initialized
      if (TypeCode.length === 0) {
        const newTypeCodes = orderDetail.map((detail) => {
          console.log(
            "detail itemList typeCode:",
            detail.itemList[0]?.typeCode
          ); // Debug
          return detail.itemList[0]?.typeCode || null;
        });
        setTypeCode(newTypeCodes);
        console.log("TypeCode initialized:", newTypeCodes); // Debug TypeCode initialization
      }

      // Initialize statusDetail if not already initialized
      if (statusDetail.length === 0) {
        const initialStatusDetail = orderDetail.map(
          (detail) => detail.status.key
        );
        setStatusDetail(initialStatusDetail);
        console.log("statusDetail initialized:", initialStatusDetail); // Debug statusDetail initialization
      }
    }
    console.log("optTipe:", optTipe);
  }, [orderDetail]);

  useEffect(() => {
    console.log("DataTipe", DataTipe);
  }, [DataTipe]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/items`);
      console.log(response.data.itemList);
      setItemData(response.data.itemList);
    } catch (error) {
      console.error("Error fetching item list:", error.response.data.itemList);
    }
  };
  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders`);
      console.log(response.data.orderList);
      setOrderData(response.data.orderList);
    } catch (error) {
      console.error(
        "Error fetching order list:",
        error.response.data.orderList
      );
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders/status`);
      console.log(response.data.orderStatusList);
      setOrderStatus(response.data.orderStatusList);
    } catch (error) {
      console.error(
        "Error fetching order list:",
        error.response.data.orderList
      );
    }
  };

  useEffect(() => {
    console.log(cleanedBackendUrl);
    fetchOrders();
    fetchItems();
    fetchStatus();
  }, []);

  useEffect(() => {
    console.log("Updated orderData:", orderData);

    console.log("Updated itemData:", itemData);
    console.log("Updated orderStatus:", orderStatus);
    setFilteredOrderData(orderData);
  }, [orderData, itemData, orderStatus]);

  useEffect(() => {
    const slicedIds = filteredOrderData.map((order) => order.id.slice(1, 4));
    console.log("Sliced IDs Array:", slicedIds);
    setOrderid(slicedIds);
  }, [filteredOrderData]);

  const getStatusColor = (key) => {
    let bg = ''
    let text = ''

    if (key === 'DRAFT') {
      bg = '#A0A0A0'
      text = 'black'
    } else if (key === 'WAITING_FOR_PAYMENT') {
      bg = '#FE8A01'
      text = 'white'
    } else if (key === 'PAID') {
      bg = '#5DB7DE'
      text = 'black'
    } else if (key === 'PACKAGED') {
      bg = '#0074CC'
      text = 'white'
    } else if (key === 'ON_DELIVERY') {
      bg = '#9B30FF'
      text = 'white'
    } else if (key === 'DELIVERED') {
      bg = '#19B600'
      text = 'white'
    } else if (key === 'CANCELED') {
      bg = '#E50B0B'
      text = 'white'
    }

    return {
      background: bg,
      color: text
    }
  }

  const validate = () => {
    let tempErrors = {};
    if (!reason) {
      tempErrors.reason = "Alasan refund harus diisi";
    }

    if (!bank) {
      tempErrors.bank = "Bank/E-Wallet Tujuan harus diisi"
    }

    if (!accountNo) {
      tempErrors.accountNo = "No. Rekening / HP harus diisi"
    }

    setFormErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleOpenRefundList = (order) => {
    setSelectedOrder(order)
    setRefundList(order.refunds);
    setOpenRefundList(true);
  }

  const handleCloseRefundList = () => {
    setOpenRefundList(false);
    setRefundList([]);
  }

  const handleCloseAddRefund = () => {
    setOpenAddRefund(false);
    setReason("");
    setBank(null);
    setAccountNo("");
  }

  const handleAddRefund = async (e) => {
    e.preventDefault();
    if(validate()) {
      try {
        setLoadingAddRefund(true);
        const response = await axios.post(
          `${cleanedBackendUrl}/refund/admin`,
          {
            username: selectedOrder?.username,
            paymentReferenceId: selectedOrder?.paymentReferenceId,
            reason: reason,
            accountNumber: accountNo,
            bankCode: bank?.bankCode
          }
        )
  
        if (response) {
          setShowSuccess(true);
          setMsgInsert("Berhasil melakukan refund");
          setTimeout(() => {
            setShowSuccess(false);
          }, 5000);
  
          setLoadingAddRefund(false);
          handleCloseAddRefund();
          handleCloseRefundList();
          fetchOrders();
        }
      } catch (error) {
        setShowError(true);
        setMsgError("Gagal Membuat Refund");
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      } finally {
        setLoadingAddRefund(false);
      }
    } else {
      console.log(formErrors)
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <SupervisorSidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc((100% - ${drawerWidth}px))` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getusername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ ml: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openDelete}
          onClose={handleCloseDelete}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openDelete}>
            <Box sx={styleModal}>
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah kamu yakin ingin membuang data ini?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleConfirmDelete}
                  sx={{ mr: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Ya
                </Button>
                <Button variant="outlined" onClick={handleCloseDelete}>
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <br></br>
        {ShowSuccess && (
          <Alert
            variant="filled"
            severity="success"
            style={{ marginBottom: 3 }}
          >
            {MsgInsert}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginBottom: 3 }}>
            {msgError}
          </Alert>
        )}
        <Grid
          container
          sx={{
            minWidth: 300,
            width: "100%",
            paddingTop: "24px",
            rowGap: "24px",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h3">Pesanan</Typography>
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: "100%", maxWidth: "100%" }}>
          {page === "main" ? (
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                sx={{
                  minWidth: 300,
                  width: "100%",
                  paddingTop: "24px",
                  rowGap: "24px",
                }}
              >
                <Grid item xs={3} sx={{ paddingRight: "24px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Cari"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ paddingLeft: 0 }}
                  />
                </Grid>
                <Grid item xs={9} sx={{ position: "absolute", right: "24px" }}>
                  <Button
                    style={btnUpdatePesanan}
                    onClick={handleUpdatePesanan}
                  >
                    {updateOrderIsLoading ? (
                      <>
                        <CircularProgress size={24} color="inherit" />{" "}
                        Loading...
                      </>
                    ) : (
                      <div>Update Order Status</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  paddingTop: "24px",
                }}
              >
                <AntTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab
                    label={`SEMUA (${orderData?.length || 0})`}
                    {...a11yProps(0)}
                    onClick={() => handleNavigation("SEMUA")}
                  />
                  {orderStatus.map((status, index) => (
                    <Tab
                      key={status.key}
                      label={
                        status.label +
                        ` (${
                          orderData.filter(
                            (order) => order.status.key === status.key
                          )?.length || 0
                        })`
                      }
                      {...a11yProps(index + 1)}
                      onClick={() => handleNavigation(status.key)}
                    />
                  ))}
                </AntTabs>
              </Box>
              <Grid container sx={{ minWidth: 300, width: "100%" }}>
                <Grid item xs={12}>
                  <Typography>{sortedOrderData.length} Pesanan</Typography>
                </Grid>
              </Grid>

              {
                (currentTab === 'PAID' || currentTab === 'PACKAGED') && (
                  <Grid container marginY={2}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                      <Button onClick={printOrderByStatus} style={btnPrintMassal}>Print Massal</Button>
                    </Grid>
                  </Grid>
                )
              }

              <div style={{ paddingRight: "24px", marginTop: "10px" }}>
                <Grid container height="50px" border="1px solid black">
                  <Grid item xs={3} justifyContent="center" display="flex" alignItems="center">Produk</Grid>
                  <Grid item xs={2} justifyContent="center" display="flex" alignItems="center">Tanggal Pesanan</Grid>
                  <Grid item xs={2} justifyContent="center" display="flex" alignItems="center">Total Pesanan</Grid>
                  <Grid item xs={2} justifyContent="center" display="flex" alignItems="center">Status</Grid>
                  <Grid item xs={2} justifyContent="center" display="flex" alignItems="center">Jasa Kirim</Grid>
                </Grid>
              </div>

              {
                sortedOrderData.map((order, index) => (
                  <Accordion style={{ minWidth: 750, border: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ padding: '0' }}
                    >
                      <Grid container sx={{ background: "#FE8A01", height: '50px', alignItems: 'center', padding: '0 10px', border: '1px solid black' }}>
                        <Grid item xs={10}sx={{ display: 'flex', alignItems: 'center' }}>
                          {order.username ? order.username + ' - ' : '' } {order.phoneNumber}
                        </Grid>
                        <Grid item xs={2} sx={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                          {
                            (
                              order.orderList[0].status.key === "DRAFT" ||
                              order.orderList[0].status.key === "WAITING_FOR_PAYMENT"
                            ) 
                            && <Button onClick={(event) => toEdit(index, event)} style={{ color: 'white', background: 'black' }}>Update</Button> 
                          }
                          {
                            (
                              order.orderList[0].status.key === "PAID"
                            ) 
                            && <Button onClick={() => handleOpenRefundList(order)} style={{ color: 'white', background: '#E50B0B' }}>Refund</Button> 
                          }
                          {
                            (
                              order.orderList[0].status.key !== "DRAFT" &&
                              order.orderList[0].status.key !== "WAITING_FOR_PAYMENT" &&
                              order.orderList[0].status.key !== "PAID"
                            ) 
                            && <Button disabled>Update</Button>
                          }
                          {
                            (
                              order.orderList[0].status.key === "PAID" || order.orderList[0].status.key === "PACKAGED"
                            ) 
                            && <Button onClick={(event) => printOrder(order.orderList[0].deliveryReceiptNumber, event)} 
                                disabled={order.orderList[0].deliveryReceiptNumber == null}
                                style={order.orderList[0].deliveryReceiptNumber ? { color: 'black', background: '#5DB7DE', marginLeft: "10px" } : {marginLeft: "10px"}}
                                >
                                  Print
                                </Button> 
                          }
                        </Grid>
                      </Grid>
                      {
                        order.orderList.map((orderData, index) => {
                          return orderData.itemList.map((item, itemIndex) => {
                            const style = { padding: "0 10px", borderRight: '1px solid black', borderLeft: '1px solid black' };
                            if (itemIndex === orderData.itemList.length - 1 && index === order.orderList.length -1) {
                              style.borderBottom = '1px solid black'
                            }

                            return (
                              <Grid container sx={style}>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {item?.typeCode || "-"}{" "}
                                  {orderData.id.slice(1, 4)} -{" "}
                                  {item?.typeVariant || "-"}{" "}
                                  - {orderData.id}
                                </Grid>
                                <Grid xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {orderData.updatedDate}
                                </Grid>
                                <Grid xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <NumericFormat
                                    value={orderData.price}
                                    displayType={"text"}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix={"Rp. "}
                                  />
                                </Grid>
                                <Grid xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Chip label={orderData?.status?.label} style={getStatusColor(orderData?.status?.key)}></Chip>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                  JNT :<br />
                                  {orderData?.deliveryReceiptNumber || "-"}
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                  <Link
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Stop event from bubbling up to the Grid onClick handler
                                      handleCopy(
                                        "www.liquestore.com/#/register?orderid=" +
                                          orderData.id
                                      );
                                    }}
                                  >
                                    Copy Link
                                  </Link>
                                </Grid>
                              </Grid>
                            )
                          })
                        })
                      }
                    </AccordionSummary>
                    <AccordionDetails style={{ background: "black", color: "white", marginRight: "24px" }}>
                      {
                        order?.paymentReferenceId ? (
                          <Grid container sx={{ color: "white", padding: "20px" }}>
                            <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: {xs: "10px", sm: "10px", md: "0"} }} 
                              height="auto" display="flex" flexDirection="column" justifyContent="end">
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="h6">Last Update</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="p">
                                    {order.orderList[0]?.updatedDate}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container marginTop={2}>
                                <Grid item xs={12}>
                                  <Typography variant="h6">Alamat Pengiriman</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="p">
                                    {order.orderList[0]?.addressDetail}, {order.orderList[0]?.addressNeighborhood}, {order.orderList[0]?.addressSubdistrict}, {order.orderList[0]?.addressDistrict}, {order.orderList[0]?.addressProvince}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <Grid container columnSpacing={3} rowSpacing={1}>
                                <Grid item xs={6} textAlign="end">
                                  <Typography variant="h6">Total Pesanan</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h6">
                                    <NumericFormat
                                      value={order.orderList[0]?.paymentPrice}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} textAlign="end">
                                  <Typography variant="h6">Ongkos Kirim</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h6">
                                    <NumericFormat
                                      value={order.orderList[0]?.paymentDeliveryFee}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} textAlign="end">
                                  <Typography variant="h6">Biaya Admin</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h6">
                                    <NumericFormat
                                      value={order.orderList[0]?.paymentTransactionFee}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} textAlign="end">
                                  <Typography variant="h6">Diskon</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h6">
                                    <NumericFormat
                                      value={0}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} textAlign="end">
                                  <Typography variant="h6">Total Penjualan</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h5">
                                    <NumericFormat
                                      value={order.orderList[0]?.paymentTotalPrice}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                              Belum ada pembayaran
                            </Grid>
                          </Grid>
                        )
                      }
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>Nomor WA: </Typography>
                <TextField
                  value={orderDetail[0]?.phoneNumber || ""} // Safely access the first item
                  fullWidth
                  type="text"
                  autoComplete="off"
                  onChange={(e) => handlePhoneNumberChange(e)}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  {Array.isArray(orderDetail) && orderDetail.length > 0 ? (
                    orderDetail.map((detail, index) => (
                      <Grid
                        container
                        rowSpacing={3}
                        sx={{
                          padding: "24px 0",
                          // paddingRight: "0px",
                        }}
                        key={index}
                      >
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            Item {index + 1}:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>ID:</Typography>
                          <TextField
                            fullWidth
                            type="text"
                            autoComplete="off"
                            value={detail.id}
                            onValueChange={(values) =>
                              handleIdChange(values, index)
                            }
                            disabled
                          ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Status:</Typography>
                          {statusDetail.length > 0 && (
                            <Select
                              labelId="status-select-label"
                              id={`status-select-${index}`}
                              value={statusDetail[index] || ""} // Use statusDetail state to track the value
                              onChange={(event) =>
                                handleChangeStatus(event, index)
                              } // Pass the index to handle the change
                              fullWidth
                            >
                              {statusOptions.map((statusOption) => (
                                <MenuItem
                                  key={statusOption.key}
                                  value={statusOption.key}
                                >
                                  {statusOption.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            sx={{
                              width: 36,
                              height: 36,
                              borderRadius: "2px",
                              bgcolor: hexcode[index], // Use hexcode corresponding to this detail
                              outline: "2px solid black",
                              outlineOffset: "4px",
                              marginLeft: "10px"
                            }}
                          ></Box>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            {detail.itemList[0]?.typeCode} {"("} {OrderidDetail}{" "}
                            {")"} - {detail.itemList[0]?.typeName} -{" "}
                            {detail.itemList[0]?.typeVariant}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <Typography>Kode Barang</Typography>
                        <Autocomplete
                          fullWidth
                          options={optTipe}
                          getOptionLabel={(option) =>
                            option.name + " - " + option.varian
                          }
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            optTipe.find(
                              (option) =>
                                option.value === (TypeCode[index] || null) // Ensure typeCode[index] is used
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            handleTypeChange(event, newValue, index)
                          }
                        />
                      </Grid> */}
                        <Grid item xs={12}>
                          <Typography>Kode Barang</Typography>
                          <Autocomplete
                            fullWidth
                            options={optTipe}
                            getOptionLabel={(option) =>
                              option.name + " - " + option.varian
                            }
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              optTipe.find(
                                (option) =>
                                  option.value === (TypeCode[index] || null) // Ensure TypeCode is correctly matched with optTipe
                              ) || null
                            }
                            onChange={(event, newValue) =>
                              handleTypeChange(event, newValue, index)
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Price: </Typography>
                          <React.Fragment>
                            <NumericFormat
                              fullWidth
                              type="text"
                              value={detail.price || ""}
                              thousandSeparator="."
                              decimalSeparator=","
                              customInput={TextField}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Rp.{" "}
                                  </InputAdornment>
                                ),
                              }}
                              onValueChange={(values) =>
                                handlePriceChange(values, index)
                              }
                              variant="outlined"
                            />
                          </React.Fragment>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography onClick={() => handleOpenDelete(index)}>
                            -Remove item
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography>No order details available.</Typography>
                  )}
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      padding: "24px 0",
                      // paddingRight: "0px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          border: "solid 2px black",
                        }}
                        onClick={(e) => {
                          handleSubmit(); // Call the second function with the event object
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        onClick={toMain}
                        fullWidth
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          border: "solid 2px black",
                        }}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Box>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openRefundList}
        onClose={handleCloseRefundList}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRefundList}>
          <Box sx={styleModalRefundList}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Daftar Refund
            </Typography>
            <Grid container justifyContent="center">
              {
                refundList.map(refund => (
                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <Card sx={{ width: "100%", background: "#000", color: "#fff" }}>
                      <CardContent>
                        <Typography variant="h6">
                          Informasi Refund
                        </Typography>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={4}>
                            No. Refund
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.referenceId }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Alasan Refund
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.reason }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Bank / E-Wallet
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ bankCodes.find(bank => bank.bankCode === refund.bankCode)?.name }</b>
                          </Grid>

                          <Grid item xs={4}>
                            No. Rekening / HP
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.accountNumber }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Status
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.status }</b>
                          </Grid>

                          <Grid item xs={4}>
                            Status
                          </Grid>
                          <Grid item xs={8}>
                            <b>{ refund.requestDate }</b>
                          </Grid>

                          {
                            refund.status === 'REJECTED' && (
                              <>
                                <Grid item xs={4}>
                                  Alasan Penolakan
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.rejectReason }
                                </Grid>
          
                                <Grid item xs={4}>
                                  Tanggal Penolakan
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.acceptedDate }
                                </Grid>
                              </>
                            )
                          }
          
                          {
                            !['WAITING', 'REJECTED'].includes(refund.status) && (
                              <>
                                <Grid item xs={4}>
                                  Tanggal Disetujui
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.acceptedDate }
                                </Grid>
                                
                                <Grid item xs={4}>
                                  Tanggal Selesai
                                </Grid>
                                <Grid item xs={8}>
                                  { refund.completeDate }
                                </Grid>
                              </>
                            )
                          }
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              }
              {
                refundList.length === 0 && (
                  <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "20px" }}>
                    <Typography variant="p">
                      Belum ada data refund
                    </Typography>
                  </Grid>
                )
              }
              {
                ( refundList.length === 0 || ["REJECTED", "FAILED"].includes(refundList[0]?.status) )
                  && (
                    <Grid item xs={6}>
                      <Button onClick={() => setOpenAddRefund(true)} style={btnPaymentStyle}>Add Refund</Button>
                    </Grid>
                  )
              }
            </Grid>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openAddRefund}
        onClose={handleCloseAddRefund}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddRefund}>
          <Box sx={styleModalRefundAdd}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Request Refund
            </Typography>
            <form>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={12}>
                  <Typography>Bank Tujuan *</Typography>
                  <Autocomplete 
                    options={bankCodes}
                    getOptionLabel={(option) => `${option.name} (${option.type})`}
                    value={bank ? {...bank} : null}
                    isOptionEqualToValue={(option, value) => {
                      return option.bankCode === value.bankCode
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    onChange={(event, newValue) => setBank(newValue)} />
                  {!!formErrors.bank ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {formErrors.bank}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Typography>No Rekening / HP *</Typography>
                  <TextField
                    fullWidth
                    value={accountNo}
                    error={!!formErrors.accountNo}
                    helperText={formErrors.accountNo}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    onChange={(e) => setAccountNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} spacing={1}>
                  <Typography>Alasan Refund *</Typography>
                  <TextareaAutosize
                    style={{ width: '100%' }}
                    minRows={3}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {!!formErrors.reason ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {formErrors.reason}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleAddRefund}
                    fullWidth
                    disabled={loadingAddRefund}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default PemesananPage2;
